//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isClient } from "@storefront-ui/vue/src/utilities/helpers";
import SfButton from "@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue";
import SfScrollable from "@storefront-ui/vue/src/components/molecules/SfScrollable/SfScrollable.vue";
export default {
  name: "Tab",
  components: {
    SfScrollable,
    SfButton,
  },
  inject: ["tabConfig"],
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isActive: false,
      desktopMin: 1024,
    };
  },
  computed: {
    tabMaxContentHeight() {
      return this.tabConfig.tabMaxContentHeight;
    },
    tabShowText() {
      return this.tabConfig.tabShowText;
    },
    tabHideText() {
      return this.tabConfig.tabHideText;
    },
  },
  methods: {
    tabClick() {
      if (!isClient) return;
      const width = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth
      );
      if (this.isActive && width > this.desktopMin) return;
      this.$parent.$emit("toggle", this._uid);
    },
  },
};
